import CartServices from "@/services/CartServices";
import productMixins from "@/mixins/product.mixins";
  
const state = () => ({
  cartList: [],
  cartUser: [],
  cart: localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : {
    products: [],
    voucher: {}
  },
  cartDraft: localStorage.getItem('drafts') ? JSON.parse(localStorage.getItem('drafts')) : [],
  cartLoading: false,
  cartPayment: localStorage.getItem('cart-payment') ? JSON.parse(localStorage.getItem('cart-payment')) : {},
  extraValue: localStorage.getItem('extra-value') ? parseInt(localStorage.getItem('extra-value')) : 0 ,
  cartUseGroceryPrice: false,
  voucher: {},
  cartCollapse: true
});

const mutations = {
  SET_CART_LOADING (state, payload) {
    state.cartLoading = payload
  },
  ADD_GUEST_CART( state, payload){
    state.cart = payload
    localStorage.setItem("cart", JSON.stringify(state.cart));

  },
  SET_CART(state,payload){
   state.cart = payload
   localStorage.setItem('cart', JSON.stringify(payload))
  },
  SET_CART_DRAFT(state,payload){
    state.cartDraft = payload
    localStorage.setItem('drafts', JSON.stringify(payload))
  },
  RESET_CART(){
    state.cart = []
    localStorage.removeItem('cart')
    localStorage.removeItem('cart-payment')
  },
  SET_CART_USER(state, payload){
    state.cartUser = payload
    state.cartType = 'Qr'
  },
  RESET_CART_USER(){
    localStorage.removeItem('cartQr')
    localStorage.removeItem('cart-payment')
    localStorage.removeItem('cart')

  },
  SET_CHECKOUT(state, payload){
    state.checkout = payload
  },
  SET_CART_PAYMENT(state, payload)  {
    state.cartPayment = payload
    localStorage.setItem('cart-payment', JSON.stringify(payload))
  
  },
  DELETE_CART_PAYMENT(state) {
    state.cartPayment = {}
    localStorage.removeItem('cart-payment')
    localStorage.removeItem('cart')
  },
  SET_CART_EXTRA_VALUE(state, payload){
    state.extraValue = payload
    localStorage.setItem('extra-value', payload)
  },
  SET_CART_USE_GROCERY_PRICE (state, payload) {
    state.cartUseGroceryPrice = payload
    localStorage.setItem('use-grocery-price', payload)
  },
  SET_VOUCHER(state,payload){
    state.voucher = payload
  },
  SET_CART_COLLAPSE(state,payload){
    state.cartCollapse = payload
  },
  RESET_VOUCHER(){
    state.voucher = {}
    localStorage.removeItem('voucher')
  },
}

const actions = {
  addCart({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CartServices.addCart(payload)
      .then(response => {
        commit("SET_CART", response.data.data.cart);

        resolve(response);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
    });
  },
  addCartMultiple({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CartServices.addCartMultiple(payload)
      .then(response => {
        commit("SET_CART", response.data.data.cart);

        resolve(response);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
    });
  },
  getCart({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CartServices.getCart(payload)
      .then(response => {
        if (response.data.status_code === 200) {
          commit("SET_CART", response.data.data.cart);
          const vouchers = response.data.data.cart.vouchers;
          const discountVouchers = vouchers.filter(voucher => voucher.type === "discount");
          commit("SET_VOUCHER", discountVouchers.length ? discountVouchers[0] : {});
        }
        resolve(response);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
    });
  },
  editProductCart({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CartServices.editProductCart(payload)
      .then(response => {
        commit("SET_CART", response.data.data.cart);
        resolve(response);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
    });
  },
   deleteProductCart({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CartServices.deleteProductCart(payload)
      .then(response => {
        commit("SET_CART", response.data.data.cart);
        resolve(response);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
    });
  },
  emptyCart({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CartServices.emptyCart(payload)
      .then(response => {
        commit("SET_CART", response.data.data.cart);
        resolve(response);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
    });
  },
  sendAdditionalCost(_, payload) {
    return new Promise((resolve, reject) => {
      CartServices.sendAdditionalCost(payload)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
    });
  },
  getCartbyQr({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CartServices.getCartbyQr(payload)
      .then(response => {
        if (response.data.message === "success") {
          commit("SET_CART_USER", response.data.data);
        }
        resolve(response);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
    });
  },
  addOrder({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CartServices.addOrder(payload)
      .then(response => {
        if (response.data.message === "success") {
          commit("SET_CHECKOUT", response.data.data);
        }
        resolve(response);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
    });
  },
  useVoucher(_, payload) {
    return new Promise((resolve, reject) => {
      CartServices.useVoucher(payload)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
    });
  },
  setUseGroceries(_, payload) {
    return new Promise((resolve, reject) => {
      CartServices.setUseGroceries(payload)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
    });
  
  }
}

const getters = {

  productAvailable (state) {
    if(Object.keys(state.cart).length) {
      if(state.cart.products.length) {
        return state.cart.products.filter(product => {
          return product.stock > 0
        })
      }
    } 

    return []
    
  },
  productUnavailable (state) {
    if(Object.keys(state.cart).length) {
      if(state.cart.products.length) {
        return state.cart.products.filter(product => {
          return !product.stock
        })
      }
    } 

    return []
    
  },
  productTotal(_, getters) {
    let total = 0
    if(getters.productAvailable.length){
      getters.productAvailable.forEach(item => {
          total += item.quantity
      })
      return total
    }else{
      return 0
    }
  },
  subtotal(state, getters){
    let total = 0
    if(getters.productAvailable.length){
      getters.productAvailable.forEach(item => {
          total += item.quantity * (state.cartUseGroceryPrice ? productMixins.methods.productPriceDetail(item).price : item.discounted_price ? item.discounted_price : item.price)
      })
      return total
    }else{
      return 0
    }
  },
  discount(state){
    let total = 0
    let cartItem = state.cartList
    if(cartItem){
      cartItem.forEach(c => {
        total += c.total_discount
      })
      return total
    }else{
      return 0
    }
  },
  total(state){
    let total = 0
    let cartItem = state.cartList
    if(cartItem){
      cartItem.forEach(c => {
        total += c.total_price
      })
      return total
    }else{
      return 0
    }
  },
  voucherAmmount(state, getters) {
    let voucher = state.voucher
    if(Object.keys(voucher).length){
      if(voucher.reduction_type === "percent"){
        let discount = (voucher.value/100)*getters.subtotal
        return discount
      } else {
        return voucher.value
      }
    } else {
      return 0
    }
  },
  finalPrice(state, getters){
    if (getters.productAvailable.length) {
      if(getters.voucherAmmount){
        return ((getters.subtotal - getters.discount) - getters.voucherAmmount > 10000 ? (getters.subtotal - getters.discount) - getters.voucherAmmount : 10000 ) + state.extraValue
      } else {
        return getters.subtotal + state.extraValue
      }
    }else{
      return 0
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};