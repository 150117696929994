import Vue from 'vue'
import Vuex from 'vuex'

import auth from './store/modules/auth.module'
import home from './store/modules/home.module'
import cart from './store/modules/cart.module'
import category from './store/modules/category.module'
import product from './store/modules/product.module'
import paymentMethod from './store/modules/paymentMethod.module'
import order from './store/modules/order.module'
import voucher from './store/modules/voucher.module'
import payment from './store/modules/payment.module'
import user from './store/modules/user.module'
import shift from './store/modules/shift.module'
import numpad from './store/modules/numericPad.module'
import returns from './store/modules/return.module'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth,
    home,
    cart,
    category,
    product,
    paymentMethod,
    voucher,
    order,
    payment,
    user,
    shift,
    numpad,
    returns
  }
});

export default store