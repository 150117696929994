import PaymentServices from "@/services/PaymentServices";

const state = () => ({
  paymentMethods: [],
  selectedPaymentMethodType: {
    name: '',
    payment_methods: []
  },
  selectedPaymentMethodType1: {},
  selectedPaymentMethodType2: {},
  selectedPaymentMethod: {},
  selectedPaymentMethod1: {},
  selectedPaymentMethod2: {},
  paymentProof: {
    payment_proof_image: ''
  },
  inputData: '0',
  usePoint: localStorage.getItem('usePoint') ? localStorage.getItem('usePoint') === 'true' ? true : false : false
})

const mutations = {
  SET_PAYMENT_METHOD(state, payload) {
    state.paymentMethods = payload
  },
  SET_SELECTED_PAYMENT_METHOD_TYPE(state, payload) {
    state.selectedPaymentMethodType = payload
    if (payload.payment_methods.length) {
      state.selectedPaymentMethod = payload.payment_methods[0]
    }
  },
  SET_SELECTED_PAYMENT_METHOD_TYPE1(state, payload) {
    state.selectedPaymentMethodType1 = payload
    if (payload.payment_methods.length) {
      state.selectedPaymentMethod1 = payload.payment_methods[0]
    }
  },
  SET_SELECTED_PAYMENT_METHOD_TYPE_2(state, payload) {
    state.selectedPaymentMethodType2 = payload
    if (payload.payment_methods.length) {
      state.selectedPaymentMethod2 = payload.payment_methods[0]
    }
  },
  SET_SELECTED_PAYMENT_METHOD(state, payload) {
    state.selectedPaymentMethod = payload
    state.inputData = '0'
    localStorage.setItem('paymentInput', '0')
  },
  SET_SELECTED_PAYMENT_METHOD_1(state, payload) {
    state.selectedPaymentMethod1 = payload
    state.inputData = '0'
    localStorage.setItem('paymentInput', '0')

  },
  SET_SELECTED_PAYMENT_METHOD_2(state, payload) {
    state.selectedPaymentMethod2 = payload
    state.inputData = '0'
    localStorage.setItem('paymentInput', '0')

  },
  SET_SELECTED_PAYMENT_IMAGE_PROOF(state, payload) {
    state.paymentProof.payment_proof_image = payload
  },
  SET_INPUT_DATA(state, payload) {
    state.inputData = payload
    localStorage.setItem('paymentInput', payload)
  },
  SET_USE_POINT(state, payload) {
    state.usePoint = payload
    localStorage.setItem('usePoint', payload)
  }
}

const actions = {
  getPaymentMethodList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      PaymentServices.getPaymentMethodList(payload)
        .then(response => {
          if (response.data.status_code === 200) {
            commit("SET_PAYMENT_METHOD", response.data.data.payment_method_types);
            commit("SET_SELECTED_PAYMENT_METHOD_TYPE", response.data.data.payment_method_types.filter(item => item.name === 'Tunai')[0]);
          }
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  setPaymentUsePoint(_, payload) {
    return new Promise((resolve, reject) => {
      PaymentServices.setPaymentUsePoint(payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}