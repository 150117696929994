import { apiClientAuth } from "@/config";

export default {
  async getVouchers(payload) {
    return apiClientAuth({
      method: "get",
      url: "/api/vouchers",
      ...payload
    });
  }
}