import VoucherServices from "@/services/VoucherServices";
const state = () => ({
  voucherList: [],
  previewVoucherSelected: {},
  voucherDetail: {}
})

const mutations = {
  SET_LIST_VOUCHER(state, payload) {
    state.voucherList = payload
  },
  SET_VOUCHER_DETAIL(state, payload) {
    state.voucherDetail = payload
  },
  SET_PREVIEW_VOUCHER_SELECTED(state, payload) {
    state.previewVoucherSelected = payload
  }
}

const actions = {
  getVouchers({ commit }, payload) {
    return new Promise((resolve, reject) => {
    VoucherServices.getVouchers(payload)
        .then(response => {
          if (response.data.status_code === 200) {
            if (response.status === 200) {
                commit("SET_LIST_VOUCHER", response.data.data.vouchers);
            }
          }
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}